var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-card",
        { staticStyle: { "box-sizing": "border-box", padding: "30px" } },
        [
          _c("a-form", [
            _c(
              "div",
              { staticStyle: { display: "flex", "flex-direction": "row" } },
              [
                _c(
                  "a-form-item",
                  { staticClass: "table-head-layout", attrs: { label: "" } },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "300px" },
                        on: { change: _vm.changeAppId },
                        model: {
                          value: _vm.appId,
                          callback: function ($$v) {
                            _vm.appId = $$v
                          },
                          expression: "appId",
                        },
                      },
                      [
                        _c("a-select-option", { key: "" }, [
                          _vm._v("应用APPID"),
                        ]),
                        _vm._l(_vm.mchAppList, function (item) {
                          return _c("a-select-option", { key: item.appId }, [
                            _vm._v(
                              _vm._s(item.appName) +
                                " [" +
                                _vm._s(item.appId) +
                                "]"
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          !_vm.appId
            ? _c("a-divider", [_vm._v("请选择应用APPID")])
            : _vm.noConfigText
            ? _c("a-divider", [_vm._v("您尚未配置任何支付方式")])
            : _c("a-divider"),
          _vm.payTestShow()
            ? _c(
                "div",
                { staticClass: "paydemo", staticStyle: { width: "100%" } },
                [
                  _c("div", { staticClass: "paydemo-type-content" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showTitle("WX"),
                            expression: "showTitle('WX')",
                          },
                        ],
                        staticClass: "paydemo-type-name article-title",
                      },
                      [_vm._v("微信支付")]
                    ),
                    _c("div", { staticClass: "paydemo-type-body" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.appPaywayList.indexOf("WX_NATIVE") >= 0,
                              expression:
                                "appPaywayList.indexOf('WX_NATIVE') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "WX_NATIVE" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "WX_NATIVE",
                                "codeImgUrl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/wx_native.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("微信二维码"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.appPaywayList.indexOf("WX_BAR") >= 0,
                              expression:
                                "appPaywayList.indexOf('WX_BAR') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "WX_BAR" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode("WX_BAR", "")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/wx_bar.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("微信条码"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.appPaywayList.indexOf("WX_JSAPI") >= 0,
                              expression:
                                "appPaywayList.indexOf('WX_JSAPI') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "WX_JSAPI" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "WX_JSAPI",
                                "codeImgUrl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/wx_jsapi.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("公众号/小程序"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.appPaywayList.indexOf("WX_H5") >= 0,
                              expression: "appPaywayList.indexOf('WX_H5') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type-h5",
                          class: { this: _vm.currentWayCode === "WX_H5" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode("WX_H5", "payurl")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/wx_h5.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("微信H5"),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showTitle("ALI"),
                            expression: "showTitle('ALI')",
                          },
                        ],
                        staticClass: "paydemo-type-name article-title",
                      },
                      [_vm._v("支付宝支付")]
                    ),
                    _c("div", { staticClass: "paydemo-type-body" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.appPaywayList.indexOf("ALI_QR") >= 0,
                              expression:
                                "appPaywayList.indexOf('ALI_QR') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "ALI_QR" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "ALI_QR",
                                "codeImgUrl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/ali_qr.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("支付宝二维码"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.appPaywayList.indexOf("ALI_BAR") >= 0,
                              expression:
                                "appPaywayList.indexOf('ALI_BAR') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "ALI_BAR" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode("ALI_BAR", "")
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/ali_bar.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("支付宝条码"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.appPaywayList.indexOf("ALI_JSAPI") >= 0,
                              expression:
                                "appPaywayList.indexOf('ALI_JSAPI') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "ALI_JSAPI" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "ALI_JSAPI",
                                "codeImgUrl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/ali_jsapi.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("支付宝生活号"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.appPaywayList.indexOf("ALI_PC") >= 0,
                              expression:
                                "appPaywayList.indexOf('ALI_PC') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "ALI_PC" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "ALI_PC",
                                "payurl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/ali_pc.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("支付宝PC网站"),
                          ]),
                        ]
                      ),
                      _c("div", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.appPaywayList.indexOf("ALI_WAP") >= 0,
                                expression:
                                  "appPaywayList.indexOf('ALI_WAP') >= 0",
                              },
                            ],
                            staticClass: "paydemo-type-h5",
                            class: { this: _vm.currentWayCode === "ALI_WAP" },
                            on: {
                              click: function ($event) {
                                return _vm.changeCurrentWayCode(
                                  "ALI_WAP",
                                  "payurl"
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "paydemo-type-img",
                              attrs: {
                                src: require("@/assets/payTestImg/ali_wap.svg"),
                              },
                            }),
                            _c("span", { staticClass: "color-change" }, [
                              _vm._v("支付宝WAP"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showJhTitle(),
                            expression: "showJhTitle()",
                          },
                        ],
                        staticClass: "paydemo-type-name article-title",
                      },
                      [_vm._v("聚合支付")]
                    ),
                    _c("div", { staticClass: "paydemo-type-body" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.appPaywayList.indexOf("WX_JSAPI") >= 0 ||
                                _vm.appPaywayList.indexOf("ALI_JSAPI") >= 0,
                              expression:
                                "appPaywayList.indexOf('WX_JSAPI') >= 0 || appPaywayList.indexOf('ALI_JSAPI') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "QR_CASHIER" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "QR_CASHIER",
                                "codeImgUrl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/qr_cashier.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("聚合扫码(用户扫商家)"),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.appPaywayList.indexOf("WX_BAR") >= 0 ||
                                _vm.appPaywayList.indexOf("ALI_BAR") >= 0,
                              expression:
                                "appPaywayList.indexOf('WX_BAR') >= 0 || appPaywayList.indexOf('ALI_BAR') >= 0",
                            },
                          ],
                          staticClass: "paydemo-type color-change",
                          class: { this: _vm.currentWayCode === "AUTO_BAR" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCurrentWayCode(
                                "AUTO_BAR",
                                "codeImgUrl"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "paydemo-type-img",
                            attrs: {
                              src: require("@/assets/payTestImg/auto_bar.svg"),
                            },
                          }),
                          _c("span", { staticClass: "color-change" }, [
                            _vm._v("聚合条码(商家扫用户)"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("a-divider"),
                  _c("div", { staticClass: "paydemo-type-content" }, [
                    _c(
                      "div",
                      { staticClass: "paydemo-type-name article-title" },
                      [_vm._v("支付信息")]
                    ),
                    _c(
                      "form",
                      { staticClass: "layui-form" },
                      [
                        _c("div", { staticClass: "paydemo-form-item" }, [
                          _c("label", [_vm._v("订单编号：")]),
                          _c("span", { attrs: { id: "payMchOrderNo" } }, [
                            _vm._v(_vm._s(_vm.mchOrderNo)),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "paydemo-btn",
                              staticStyle: { padding: "0 3px" },
                              on: { click: _vm.randomOrderNo },
                            },
                            [_vm._v("刷新订单号")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "paydemo-form-item" },
                          [
                            _c("label", [_vm._v("订单标题：")]),
                            _c("a-input", {
                              staticStyle: { width: "200px" },
                              model: {
                                value: _vm.orderTitle,
                                callback: function ($$v) {
                                  _vm.orderTitle = $$v
                                },
                                expression: "orderTitle",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "paydemo-form-item" },
                          [
                            _c("label", [_vm._v("分账方式：")]),
                            _c(
                              "a-radio-group",
                              {
                                staticStyle: { display: "flex" },
                                model: {
                                  value: _vm.divisionMode,
                                  callback: function ($$v) {
                                    _vm.divisionMode = $$v
                                  },
                                  expression: "divisionMode",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("a-radio", { attrs: { value: 0 } }, [
                                      _vm._v("订单不分账"),
                                    ]),
                                    _c("a-radio", { attrs: { value: 1 } }, [
                                      _vm._v("支付完成自动分账"),
                                    ]),
                                    _c("a-radio", { attrs: { value: 2 } }, [
                                      _vm._v(
                                        "手动分账（冻结商户资金， 只能通过API发起分账后解冻）"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("a-divider"),
                        _c(
                          "div",
                          { staticClass: "paydemo-form-item" },
                          [
                            _c("span", [_vm._v("支付金额(元)：")]),
                            _c(
                              "a-radio-group",
                              {
                                staticStyle: { display: "flex" },
                                attrs: {
                                  name: "radioGroup",
                                  "default-value": 0.01,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { display: "flex" },
                                    on: {
                                      click: function ($event) {
                                        _vm.amountInput = false
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      {
                                        attrs: { value: 0.01 },
                                        on: {
                                          click: function ($event) {
                                            _vm.paytestAmount = 0.01
                                          },
                                        },
                                      },
                                      [_vm._v("￥0.01")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        attrs: { value: 0.15 },
                                        on: {
                                          click: function ($event) {
                                            _vm.paytestAmount = 0.15
                                          },
                                        },
                                      },
                                      [_vm._v("￥0.15")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        attrs: { value: 0.21 },
                                        on: {
                                          click: function ($event) {
                                            _vm.paytestAmount = 0.21
                                          },
                                        },
                                      },
                                      [_vm._v("￥0.21")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        attrs: { value: 0.29 },
                                        on: {
                                          click: function ($event) {
                                            _vm.paytestAmount = 0.29
                                          },
                                        },
                                      },
                                      [_vm._v("￥0.29")]
                                    ),
                                    _c(
                                      "a-radio",
                                      {
                                        attrs: { value: 0.64 },
                                        on: {
                                          click: function ($event) {
                                            _vm.paytestAmount = 0.64
                                          },
                                        },
                                      },
                                      [_vm._v("￥0.64")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-radio",
                                  { on: { click: _vm.amountInputShow } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "3px" },
                                      },
                                      [_vm._v("自定义金额")]
                                    ),
                                    _c("a-input-number", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.amountInput,
                                          expression: "amountInput",
                                        },
                                      ],
                                      ref: "amountInputFocus",
                                      attrs: {
                                        max: 100000,
                                        min: 0.01,
                                        precision: 2,
                                      },
                                      model: {
                                        value: _vm.paytestAmount,
                                        callback: function ($$v) {
                                          _vm.paytestAmount = $$v
                                        },
                                        expression: "paytestAmount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "20px",
                              "text-align": "left",
                            },
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: {
                                  padding: "5px 20px",
                                  "background-color": "#1953ff",
                                  "border-radius": "5px",
                                  color: "#fff",
                                },
                                on: { click: _vm.immediatelyPay },
                              },
                              [_vm._v("立即支付")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("pay-test-modal", {
        ref: "payTestModal",
        on: {
          closeBarCode: function ($event) {
            _vm.$refs.payTestBarCode.visible = false
          },
        },
      }),
      _c("pay-test-bar-code", {
        ref: "payTestBarCode",
        on: {
          barCodeValue: _vm.barCodeChange,
          CodeAgainChange: _vm.testCodeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }